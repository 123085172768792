import React from 'react';
import { Grid, Box } from '@mui/material';
import HeroSection2a from './HeroSection2a'; // Importing HeroSection2a format
import HeroSection from './HeroSection';

const FeaturedGrid2x2 = () => {
  return (
    <Box sx={{ width: '90%', padding: '1rem', backgroundColor: 'white', margin: '0 auto' }}>
      <Grid container spacing={2} justifyContent="center">
        
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <Box sx={{ height: '100%', width: 'auto', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <HeroSection
              featureText="FEATURED"
              headlineText="Taylor Swift’s Iconic Makeup: The Pat McGrath red lip every Swiftie needs to try!"
              imagePath="TaylorSwift3/JackieWyersTaylorSwiftMakeup.JPG"
              linkUrl="/articles/taylor-swift-makeup"
              isFlipped={false}
            />
          </Box>
        </Grid>

        <Grid item xs={12} sm={12} md={6} lg={6}>
          <Box sx={{ height: '100%', width: 'auto', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <HeroSection
              featureText="FEATURED"
              headlineText="The Contrast Makeup Theory: How to Create a Harmonious Look"
              imagePath="https://res.cloudinary.com/dvbubqhpp/image/upload/f_webp/v1735332038/39181500-3588-48AF-8C64-A98D3FAB702A_zjqsdw.jpg"
              linkUrl="/articles/contrast-makeup-theory"
              isFlipped={false}
            />
          </Box>
        </Grid>

        <Grid item xs={12} sm={12} md={6} lg={6}>
          <Box sx={{ height: '100%', width: 'auto', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <HeroSection
              featureText="FEATURED"
              headlineText="Harley Quinn Beauty Evolution: Iconic Makeup Looks and Tutorials"
              imagePath="Harley/HARLEYQUINNTHUMBNAIL.webp"
              linkUrl="/articles/harley-quinn-evolution"
              isFlipped={true}
            />
          </Box>
        </Grid>

        <Grid item xs={12} sm={12} md={6} lg={6}>
          <Box sx={{ height: '100%', width: 'auto', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <HeroSection
              featureText="FEATURED"
              headlineText="r.e.m. Beauty x WICKED Collection Review: Glinda Makeup Magic"
              imagePath="Wicked3/GlindaJackieWyers.jpg"
              linkUrl="/articles/rembeauty/wicked-review"
              isFlipped={true}
            />
          </Box>
        </Grid>

      </Grid>
    </Box>
  );
};

export default FeaturedGrid2x2;