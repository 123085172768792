import React, { useEffect, useRef } from "react";

const SmallAdSenseAd = () => {
  const adLoaded = useRef(false); // Track if the ad was already pushed

  useEffect(() => {
    try {
      if (window.adsbygoogle && !adLoaded.current) {
        (window.adsbygoogle = window.adsbygoogle || []).push({});
        adLoaded.current = true; // Prevent multiple pushes
      }
    } catch (error) {
      console.error("Adsense error: ", error);
    }
  }, []);

  return (
    <div style={{ margin: "1rem auto", backgroundColor: "#ffffff" }}>
      <ins
        className="adsbygoogle"
        style={{ display: "block" }}
        data-ad-client="ca-pub-4660168246825318"
        data-ad-slot="6584902918"
        data-ad-format="auto"
        data-full-width-responsive="true"
      ></ins>
    </div>
  );
};

export default SmallAdSenseAd;