import React from "react";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import "./QuizSelection.css";


const QuizSelection = () => {
  const navigate = useNavigate();

  // Define button options with images and corresponding routes
  const quizOptions = [
    {
      text: "",
      backgroundImage: "https://res.cloudinary.com/dvbubqhpp/image/upload/v1739411897/YouTube_Thumbnail-72_qln1cy.jpg",
      route: "/quiz-3-home",
    },

    {
      text: "",
      backgroundImage: "https://res.cloudinary.com/dvbubqhpp/image/upload/v1739411897/YouTube_Thumbnail-73_j8vy9t.jpg",
      route: "/articles/male-female-gaze",
    },

   
    {
      text: "",
      backgroundImage: "https://res.cloudinary.com/dvbubqhpp/image/upload/v1739411897/YouTube_Thumbnail-71_tnvr2e.jpg",
      route: "/quiz-2-home",
    },
    
  ];

  return (
    <>
      <div className="quizselection-container">
        <Helmet>
          <title>Which Beauty Quiz Would You Like to Take?</title>
        </Helmet>

    

        <div className="quizselection-answer-section">
          {quizOptions.map((option, index) => (
            <button
              key={index}
              className="quizselection-answer-button"
              style={{
                backgroundImage: `url(${option.backgroundImage})`,
              }}
              onClick={() => navigate(option.route)}
            >
              {option.text}
            </button>
          ))}
        </div>
      </div>

    
    </>
  );
};

export default QuizSelection;